// Generated by Framer (2b47498)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {xmOUtt1s4: {hover: true}};

const serializationHash = "framer-bLDf5"

const variantClassNames = {xmOUtt1s4: "framer-v-y0p3o5"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const numberToPixelString = (value) => {
    if (typeof value !== "number") return value;
    if (!Number.isFinite(value)) return undefined;
    return Math.max(0, value) + "px";
};


const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({background, height, hoverBackground, id, link, padding, text, width, ...props}) => { return {...props, CoDRs75yJ: link ?? props.CoDRs75yJ, edtBHek3N: hoverBackground ?? props.edtBHek3N ?? "rgb(138, 40, 250)", gpQ8b9nko: text ?? props.gpQ8b9nko ?? "Download App", N6myLbLsH: background ?? props.N6myLbLsH ?? "rgb(146, 60, 246)", VPG3pHB8w: padding ?? props.VPG3pHB8w ?? "16px 24px 16px 24px"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;link?: string;text?: string;padding?: string;background?: string;hoverBackground?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, CoDRs75yJ, gpQ8b9nko, VPG3pHB8w, N6myLbLsH, edtBHek3N, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "xmOUtt1s4", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={CoDRs75yJ} nodeId={"xmOUtt1s4"} smoothScroll><motion.a {...restProps} {...gestureHandlers} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-y0p3o5", className, classNames)} framer-1jdkhc6`} data-framer-name={"Primary Button"} layoutDependency={layoutDependency} layoutId={"xmOUtt1s4"} ref={ref ?? ref1} style={{"--1wm2484": numberToPixelString(VPG3pHB8w), backgroundColor: N6myLbLsH, borderBottomLeftRadius: 8, borderBottomRightRadius: 8, borderTopLeftRadius: 8, borderTopRightRadius: 8, ...style}} variants={{"xmOUtt1s4-hover": {backgroundColor: edtBHek3N}}} {...addPropertyOverrides({"xmOUtt1s4-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "SW50ZXItU2VtaUJvbGQ=", "--framer-font-family": "\"Inter\", \"Inter Placeholder\", sans-serif", "--framer-font-size": "18px", "--framer-font-weight": "600", "--framer-line-height": "150%", "--framer-text-color": "var(--extracted-r6o4lv, var(--token-86940d48-67de-43e2-b492-06b7170ae465, rgb(255, 255, 255)))"}}>Download App</motion.p></React.Fragment>} className={"framer-3if7ij"} data-framer-name={"Text"} fonts={["Inter-SemiBold"]} layoutDependency={layoutDependency} layoutId={"QKdAjRjPE"} style={{"--extracted-r6o4lv": "var(--token-86940d48-67de-43e2-b492-06b7170ae465, rgb(255, 255, 255))", "--framer-paragraph-spacing": "0px"}} text={gpQ8b9nko} verticalAlignment={"top"} withExternalLayout/></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-bLDf5.framer-1jdkhc6, .framer-bLDf5 .framer-1jdkhc6 { display: block; }", ".framer-bLDf5.framer-y0p3o5 { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 4px; height: min-content; justify-content: center; overflow: visible; padding: var(--1wm2484); position: relative; text-decoration: none; width: min-content; }", ".framer-bLDf5 .framer-3if7ij { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-bLDf5.framer-y0p3o5 { gap: 0px; } .framer-bLDf5.framer-y0p3o5 > * { margin: 0px; margin-left: calc(4px / 2); margin-right: calc(4px / 2); } .framer-bLDf5.framer-y0p3o5 > :first-child { margin-left: 0px; } .framer-bLDf5.framer-y0p3o5 > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 59
 * @framerIntrinsicWidth 175
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"Ywt2ptVxq":{"layout":["auto","auto"]}}}
 * @framerVariables {"CoDRs75yJ":"link","gpQ8b9nko":"text","VPG3pHB8w":"padding","N6myLbLsH":"background","edtBHek3N":"hoverBackground"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerujtaHrifm: React.ComponentType<Props> = withCSS(Component, css, "framer-bLDf5") as typeof Component;
export default FramerujtaHrifm;

FramerujtaHrifm.displayName = "Buttons/ Primary Button";

FramerujtaHrifm.defaultProps = {height: 59, width: 175};

addPropertyControls(FramerujtaHrifm, {CoDRs75yJ: {title: "Link", type: ControlType.Link}, gpQ8b9nko: {defaultValue: "Download App", displayTextArea: false, title: "Text", type: ControlType.String}, VPG3pHB8w: {defaultValue: "16px 24px 16px 24px", title: "Padding", type: ControlType.Padding}, N6myLbLsH: {defaultValue: "rgb(146, 60, 246)", title: "Background", type: ControlType.Color}, edtBHek3N: {defaultValue: "rgb(138, 40, 250)", title: "Hover Background", type: ControlType.Color}} as any)

addFonts(FramerujtaHrifm, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://framerusercontent.com/assets/hyOgCu0Xnghbimh0pE8QTvtt2AU.woff2", weight: "600"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://framerusercontent.com/assets/NeGmSOXrPBfEFIy5YZeHq17LEDA.woff2", weight: "600"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://framerusercontent.com/assets/oYaAX5himiTPYuN8vLWnqBbfD2s.woff2", weight: "600"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://framerusercontent.com/assets/lEJLP4R0yuCaMCjSXYHtJw72M.woff2", weight: "600"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://framerusercontent.com/assets/cRJyLNuTJR5jbyKzGi33wU9cqIQ.woff2", weight: "600"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://framerusercontent.com/assets/1ZFS7N918ojhhd0nQWdj3jz4w.woff2", weight: "600"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://framerusercontent.com/assets/A0Wcc7NgXMjUuFdquHDrIZpzZw0.woff2", weight: "600"}]}], {supportsExplicitInterCodegen: true})